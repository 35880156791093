import React, { useMemo } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogsList = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulBlogPost(sort: { fields: updatedAt, order: DESC }) {
        nodes {
          id
          title
          slug
          updatedAt(formatString: "DD/MM/YYYY")
          featuredImage {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              width: 500
              aspectRatio: 1
              quality: 100
            )
          }
        }
      }
    }
  `)

  const blogs = useMemo(() => data?.allContentfulBlogPost?.nodes ?? [], [data])

  return (
    <div className="container max-w-7xl mx-auto px-6 py-20">
      <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-5">
        {blogs.map(({ id, title, slug, featuredImage, updatedAt }) => (
          <div
            key={id}
            className="p-4 border border-opacity-25 hover:border-opacity-80 transition-all duration-500 ease-in-out"
          >
            <div className="h-48 w-full relative">
              <div className="absolute top-0 left-0 w-full h-full">
                <GatsbyImage
                  image={getImage(featuredImage)}
                  className="w-full h-full"
                  alt="news thumbnail"
                />
              </div>
            </div>
            <div className="my-4 flex flex-col space-y-2">
              <span className="text-sm text-gray-400">{updatedAt}</span>

              <Link to={`/blogs/${slug.toLowerCase()}`} className="text-2xl">
                {title}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BlogsList
