import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import SmallBanner from "../../components/SmallBanner"
import BlogsList from "../../components/BlogsPage/BlogsList"

const BlogsPage = () => {
  return (
    <>
      <GatsbySeo title="Blogs" titleTemplate="%s | BESHAUS" />
      <SmallBanner
        title="Blogs"
        gatsbyImage={
          <StaticImage
            src="../../assets/images/projects-banner-background.jpg"
            className="w-full h-full"
            quality="100"
            placeholder="blurred"
            alt="blog banner background"
          />
        }
      />
      <BlogsList />
    </>
  )
}

export default BlogsPage
