import React from "react"

const Banner = ({ title, gatsbyImage }) => {
  return (
    <section className="relative">
      <div className="absolute w-full h-full top-0 left-0">{gatsbyImage}</div>
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      <div className="relative p-6 min-h-[400px] flex justify-center items-center">
        <h1 className="text-6xl font-light">{title}</h1>
      </div>
    </section>
  )
}

export default Banner
